@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html {
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}

html,
body {
  position: relative;

}

:root {
  --primary: #404194;
  --primaryHover: rgb(102, 45, 200);
  --lightSlate: #F8FAFD;
  --grayText: #9B98B8;
  --dark: #333;
  --darkSlate: #E7EAF3;
  --pure: #fff;
  --text-primary-color: #0F1063;
  --text-secondary-color: #000000;
  --magenta-light-color: #90288f;
  --green-dark-color: #1a8650;
  --green-light-color: #4caf50;
  --dashboard-bg: #f0f0f0;
  --button-bg: #D9D9D9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#select-box-input {
  padding: 6px 0 !important;
  outline: none !important;
}

select {
  background-color: white;
}

select:disabled,
input:disabled,
button[type="submit"]:disabled,
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

select,
input[type="submit"],
button[type="submit"] {
  cursor: pointer;
}

span.required {
  color: red;
}

.css-t3ipsp-control {
  border: none !important;
}

@media print {
  .report {
    padding: 2rem;
  }

  .report table {
    border: 1px solid var(--dark);
  }

  .report table td,
  .report table th {
    border: 1px solid gray;
  }
}

@media (max-width: 768px) {
  .responsiveTable tbody tr td.pivoted {
    text-align: right !important;
    /* Change text alignment to center */
  }

  .responsiveTable tbody tr {
    border: 1px solid rgb(190, 190, 190) !important;
    border-radius: 4px !important;
  }
}