.header-left svg,
.search-svg svg {
  stroke: rgb(165, 165, 165);
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-right > div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 4px 4px 12px 0px #BBC3CE99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header-right > .hamburger {
  display: none;
}

.header-right > div svg {
  stroke: #5843BE;
}

.header-notifications {
  position: relative;
}

.header-notifications > svg {
  width: 20px;
  height: 20px;
}

.header-notifications .notifications-count {
  background-color: #FF0000;
  color: #ffffff;
  top: -7px;
  right: -7px;
  z-index: 1;
  width: 15px;
  height: 15px;
  letter-spacing: 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
  opacity: 1;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  font-family: Poppins, sans-serif;
}



@media (max-width: 768px) {
  .header-right .hamburger {
    display: flex;
  }
}