#employeeReport td, #employeeReport th {
  @apply border;
}

#employeeReport th {
  @apply font-semibold p-1 text-center;
}

#employeeReport td {
  @apply p-1 whitespace-nowrap;
}

#employeeReport table {
  @apply table-auto overflow-x-auto text-center;
}

#employeeReport thead {
  @apply text-[14px] border;
}

#employeeReport tbody {
  @apply text-[13px];
}