.salary-calculations td, .salary-calculations th {
  @apply border p-2;
}

.salary-calculations th {
  @apply font-semibold text-primary text-center;
}

.salary-calculations td {
  @apply text-gray-900 whitespace-nowrap;
}

.salary-calculations table {
  @apply table-auto overflow-x-auto text-center;
}

.salary-calculations thead {
  @apply text-[14px] border;
}

.salary-calculations tbody {
  @apply text-[13px];
}